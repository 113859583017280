<template>
  <div v-if="component" :is="component" :key="component.name" />
  <div v-else>
    Dashboard
  </div>
</template>

<script>
import viewMixin from '@/app/views/_mixins/view-mixin'
import Dashboard from '@/app/views/no-user/Dashboard'

export default {
  name: 'Dashboard',
  mixins: [viewMixin],
  data() {
    return {
      component: null
    }
  },
  //beforeCreate() {
  beforeMount() {
    //const property = this.$store.getters['auth/userProperty']
    if (!this.property.id) {
      // TODO: if property count > 1
      this.$router.push({ name: 'Property selector' })
    }

    if (this.loggedUser._guard === 'no-users') {
      this.component = Dashboard
    }
  },
  mounted() {
    console.log('HERE')
    //this.$store.dispatch('menu/getMenu', 'sidebar')
  }
}
</script>
