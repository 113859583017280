<template>
  <div>
    <CCallout color="primary">
      {{ $t('Su saldo actual es: ') + $f(balance, 'currency') + ` (a ${parsers.periodName(period.month_id)})` }}
    </CCallout>

    <!--CButton color="success" @click="onButton">Button</CButton-->

    <ul class="nav nav-underline">
      <li v-if="is_valid" class="nav-item">
        <a class="nav-link" :class="{ active: selected === 'Invoices' }" name="Invoices" @click="onTabSelected" href="#">{{ $t('Invoices') }}</a>
      </li>
      <li v-if="is_valid" class="nav-item">
        <a class="nav-link" :class="{ active: selected === 'Payments' }" name="Payments" @click="onTabSelected" href="#">{{ $t('Payments') }}</a>
      </li>
      <li v-if="is_valid" class="nav-item">
        <a class="nav-link" :class="{ active: selected === 'Receipts' }" name="Receipts" @click="onTabSelected" href="#">{{ $t('Receipts') }}</a>
      </li>
      <li v-if="is_valid" class="nav-item">
        <a class="nav-link" :class="{ active: selected === 'BuildingBalance' }" name="BuildingBalance" @click="onTabSelected" href="#">{{ $t('Building balance') }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class="{ active: selected === 'CommonAreas' }" name="CommonAreas" @click="onTabSelected" href="#">{{ $t('Common areas') }}</a>
      </li>
      <li v-if="is_valid" class="nav-item">
        <a class="nav-link" :class="{ active: selected === 'Certificates' }" name="Certificates" @click="onTabSelected" href="#">{{ $t('Certificates') }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class="{ active: selected === 'Communications' }" name="Communications" @click="onTabSelected" href="#">{{ $t('Communications') }}</a>
      </li>
      <!--li class="nav-item">
        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
      </li-->
    </ul>

    <div class="tab-content" id="v-pills-tabContent">
      <div v-if="component" :is="component" :building_id="building_id" :key="component.name" />
    </div>
  </div>
</template>

<script>
import viewMixin from '@/app/views/_mixins/view-mixin'
import menuService from '@/config/services/menuService'

//import Invoices from '@/app/views/admin/invoicing/invoices/Invoices'

export default {
  name: 'Basic-Dashboard',
  mixins: [viewMixin],
  components: {
    'Invoices-dynamic': () => import('./invoices/Invoices'),
    'Payments-dynamic': () => import('./payments/Payments'),
    'Receipts-dynamic': () => import('./receipts/Receipts'),
    'BuildingBalance-dynamic': () => import('./reports/BuildingBalance'),
    //'BuildingBalance-dynamic': () => import('./reports/BuildingBalanceDetailed'),
    'CommonAreas-dynamic': () => import('./tools/common_area_reservations/CommonAreaReservations'),
    'Certificates-dynamic': () => import('./certificates/ExpensesCertificateForm'),
    'Communications-dynamic': () => import('./communications/Communications')
    //'other-dynamic': () => import('@/components/otherDynamic')
  },
  computed: {
    active(e) {
      //console.log(e)
      return { active: true }
    },
    building_id() {
      return this.$store.getters['getHeaderBuildingId']
    },
    building() {
      if (this.building_id) return this.loggedUser.property.building_list.find(item => item.id === this.building_id)
      return {}
    },
    is_valid() {
      return this.building._contact_type_id && this.building._contact_type_id !== 3
    }
  },
  data() {
    return {
      balance: 0,
      component: 'Invoices-dynamic',
      selected: 'Invoices'
    }
  },
  mounted() {
    //console.log(this.property)
  },
  methods: {
    onTabSelected(e) {
      const name = e.target.name
      if (name !== this.selected) {
        this.selected = name
        this.component = name + '-dynamic'
      }
    },
    onButton() {
      menuService
        .get()
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          console.log('ERROR', error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
.active {
  border-color: inherit;
}
</style>
